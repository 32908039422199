"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var redirect_middleware_1 = require("@codebuild/glaze/components/middlewares/redirect.middleware");
var permissions_1 = require("../common/libs/permissions/permissions");
var special_permissions_1 = require("../common/libs/permissions/special-permissions");
var authorized_middleware_1 = require("../common/middlewares/authorized.middleware");
var permission_middleware_1 = require("../common/middlewares/permission.middleware");
var unauthorized_middleware_1 = require("../common/middlewares/unauthorized.middleware");
var admin_layout_1 = require("../layouts/admin-layout/admin.layout");
var login_layout_1 = require("../layouts/login.layout");
var accept_invitation_screen_1 = require("../screens/accept-invitation.screen");
var attribute_create_screen_1 = require("../screens/attributes/attribute-create/attribute-create.screen");
var attribute_update_screen_1 = require("../screens/attributes/attribute-update/attribute-update.screen");
var attributes_options_list_screen_1 = require("../screens/attributes/attribute-values/attributes-options-list.screen");
var attributes_list_screen_1 = require("../screens/attributes/attributes-list.screen");
var b2b_kn_stock_screen_1 = require("../screens/b2b-kn-stock/b2b-kn-stock.screen");
var b2b_order_create_screen_1 = require("../screens/b2b-order/b2b-order-create.screen");
var b2b_order_list_screen_1 = require("../screens/b2b-order/b2b-order-list.screen");
var b2b_order_update_screen_1 = require("../screens/b2b-order/b2b-order-update.screen");
var b2b_channel_pricing_list_screen_1 = require("../screens/b2b-pricing/b2b-channel-pricing/b2b-channel-pricing-list.screen");
var b2b_customer_partner_pricing_list_screen_1 = require("../screens/b2b-pricing/b2b-customer-partner-pricing/b2b-customer-partner-pricing-list.screen");
var b2b_supplier_pricing_list_screen_1 = require("../screens/b2b-pricing/b2b-supplier-pricing/b2b-supplier-pricing-list.screen");
var b2b_stock_disposal_create_screen_1 = require("../screens/b2b-stock-disposal/b2b-stock-disposal-create.screen");
var b2b_stock_disposal_list_screen_1 = require("../screens/b2b-stock-disposal/b2b-stock-disposal-list.screen");
var b2b_stock_disposal_view_screen_1 = require("../screens/b2b-stock-disposal/b2b-stock-disposal-view.screen");
var b2b_stock_history_screen_1 = require("../screens/b2b-stock-history/b2b-stock-history.screen");
var b2b_stock_income_update_show_screen_1 = require("../screens/b2b-stock-income/b2b-stock-income-update-show.screen");
var b2b_stock_inventory_create_screen_1 = require("../screens/b2b-stock-inventory/b2b-stock-inventory-create.screen");
var b2b_stock_inventory_list_screen_1 = require("../screens/b2b-stock-inventory/b2b-stock-inventory-list.screen");
var b2b_stock_inventory_update_screen_1 = require("../screens/b2b-stock-inventory/b2b-stock-inventory-update.screen");
var b2b_stock_issuing_create_update_screen_1 = require("../screens/b2b-stock-issuing/b2b-stock-issuing-create-update.screen");
var b2b_stock_issuing_list_screen_1 = require("../screens/b2b-stock-issuing/b2b-stock-issuing-list.screen");
var b2b_depot_create_screen_1 = require("../screens/b2b-stock-management/b2b-depot/b2b-depot-create-update/b2b-depot-create.screen");
var b2b_depot_update_screen_1 = require("../screens/b2b-stock-management/b2b-depot/b2b-depot-create-update/b2b-depot-update.screen");
var b2b_depot_list_screen_1 = require("../screens/b2b-stock-management/b2b-depot/b2b-depot-list.screen");
var b2b_inventory_create_screen_1 = require("../screens/b2b-stock-management/b2b-inventory/b2b-inventory-create-update/b2b-inventory-create.screen");
var b2b_inventory_update_screen_1 = require("../screens/b2b-stock-management/b2b-inventory/b2b-inventory-create-update/b2b-inventory-update.screen");
var b2b_inventory_list_screen_1 = require("../screens/b2b-stock-management/b2b-inventory/b2b-inventory-list.screen");
var b2b_storage_create_screen_1 = require("../screens/b2b-stock-management/b2b-storage/b2b-inventory-create-update/b2b-storage-create.screen");
var b2b_storage_update_screen_1 = require("../screens/b2b-stock-management/b2b-storage/b2b-inventory-create-update/b2b-storage-update.screen");
var b2b_storage_list_screen_1 = require("../screens/b2b-stock-management/b2b-storage/b2b-storage-list.screen");
var b2b_warehouse_create_screen_1 = require("../screens/b2b-stock-management/b2b-warehouse/b2b-warehouse-create-update/b2b-warehouse-create.screen");
var b2b_warehouse_update_screen_1 = require("../screens/b2b-stock-management/b2b-warehouse/b2b-warehouse-create-update/b2b-warehouse-update.screen");
var b2b_warehouse_list_screen_1 = require("../screens/b2b-stock-management/b2b-warehouse/b2b-warehouse-list.screen");
var b2b_stock_reservation_channel_create_screen_1 = require("../screens/b2b-stock-reservation/channel/b2b-stock-reservation-channel-create.screen");
var b2b_stock_reservation_channel_list_screen_1 = require("../screens/b2b-stock-reservation/channel/b2b-stock-reservation-channel-list.screen");
var b2b_stock_reservation_channel_release_1 = require("../screens/b2b-stock-reservation/channel/b2b-stock-reservation-channel-release");
var b2b_stock_reservation_channel_view_screen_1 = require("../screens/b2b-stock-reservation/channel/b2b-stock-reservation-channel-view.screen");
var b2b_stock_reservation_customer_partner_create_screen_1 = require("../screens/b2b-stock-reservation/customer-partner/b2b-stock-reservation-customer-partner-create.screen");
var b2b_stock_reservation_customer_partner_list_screen_1 = require("../screens/b2b-stock-reservation/customer-partner/b2b-stock-reservation-customer-partner-list.screen");
var b2b_stock_reservation_customer_partner_release_1 = require("../screens/b2b-stock-reservation/customer-partner/b2b-stock-reservation-customer-partner-release");
var b2b_stock_reservation_customer_partner_view_screen_1 = require("../screens/b2b-stock-reservation/customer-partner/b2b-stock-reservation-customer-partner-view.screen");
var b2b_stock_transaction_create_screen_1 = require("../screens/b2b-stock-transaction/b2b-stock-transaction-create.screen");
var b2b_stock_transaction_list_screen_1 = require("../screens/b2b-stock-transaction/b2b-stock-transaction-list.screen");
var b2b_stock_transaction_show_screen_1 = require("../screens/b2b-stock-transaction/b2b-stock-transaction-show.screen");
var b2b_stock_screen_1 = require("../screens/b2b-stock/b2b-stock.screen");
var blog_screen_1 = require("../screens/blog.screen");
var bundle_create_screen_1 = require("../screens/bundle/bundle-create-update/bundle-create.screen");
var bundle_update_screen_1 = require("../screens/bundle/bundle-create-update/bundle-update.screen");
var bundle_list_screen_1 = require("../screens/bundle/bundle-list.screen");
var category_attribute_update_screen_1 = require("../screens/categories/category-attribute/category-attribute.update.screen");
var category_list_screen_1 = require("../screens/categories/category-list.screen");
var complaint_details_screen_1 = require("../screens/complaints/complaint-details/complaint-details.screen");
var complaint_list_screen_1 = require("../screens/complaints/complaint-list.screen");
var coupon_create_screen_1 = require("../screens/coupons/coupon-create-update/coupon-create.screen");
var coupon_update_screen_1 = require("../screens/coupons/coupon-create-update/coupon-update.screen");
var coupon_list_screen_1 = require("../screens/coupons/coupon-list.screen");
var customer_partner_create_screen_1 = require("../screens/customer-partner/customer-partner-create-update/customer-partner-create.screen");
var customer_partner_update_screen_1 = require("../screens/customer-partner/customer-partner-create-update/customer-partner-update.screen");
var customer_partner_list_screen_1 = require("../screens/customer-partner/customer-partner-list.screen");
var customer_list_screen_1 = require("../screens/customer/customer-list.screen");
var dashboard_screen_1 = require("../screens/dashboard.screen");
var document_archives_list_screen_1 = require("../screens/document-archives/document-archives-list.screen");
var forgot_password_screen_1 = require("../screens/forgot-password.screen");
var kn_attribute_create_screen_1 = require("../screens/kn-attribute/kn-attribute-create.screen");
var kn_attribute_list_screen_1 = require("../screens/kn-attribute/kn-attribute-list.screen");
var kn_attribute_update_screen_1 = require("../screens/kn-attribute/kn-attribute-update.screen");
var login_screen_1 = require("../screens/login.screen");
var main_menu_settings_screen_1 = require("../screens/main-menu-settings/main-menu-settings.screen");
var winery_create_screen_1 = require("../screens/manufacturer/create/winery-create.screen");
var winery_update_screen_1 = require("../screens/manufacturer/update/winery-update.screen");
var winery_list_screen_1 = require("../screens/manufacturer/winery-list.screen");
var newsletter_subscriber_list_screen_1 = require("../screens/newsletter-subscriber/newsletter-subscriber-list.screen");
var not_found_screen_1 = require("../screens/not-found.screen");
var order_sales_report_screen_1 = require("../screens/order-sales-report/order-sales-report.screen");
var order_details_screen_1 = require("../screens/order/order-details/order-details.screen");
var order_list_screen_1 = require("../screens/order/order-list.screen");
var pricing_screen_1 = require("../screens/pricing/pricing.screen");
var procurement_create_screen_1 = require("../screens/procurement/procurement-create-update/procurement-create.screen");
var procurement_update_screen_1 = require("../screens/procurement/procurement-create-update/procurement-update.screen");
var procurement_list_screen_1 = require("../screens/procurement/procurement-list.screen");
var procurement_view_screen_1 = require("../screens/procurement/procurement-view.screen");
var product_order_screen_1 = require("../screens/product-order/product-order.screen");
var product_sales_by_orders_report_screen_1 = require("../screens/product-sales-by-orders-report/product-sales-by-orders-report.screen");
var product_create_screen_1 = require("../screens/product/product-create-update/product-create.screen");
var product_update_screen_1 = require("../screens/product/product-create-update/product-update.screen");
var product_list_screen_1 = require("../screens/product/product-list.screen");
var recover_password_screen_1 = require("../screens/recover-password.screen");
var region_create_screen_1 = require("../screens/region/create/region-create.screen");
var region_screen_1 = require("../screens/region/region.screen");
var region_update_screen_1 = require("../screens/region/update/region-update.screen");
var reports_manager_screen_1 = require("../screens/reports-manager/reports-manager.screen");
var sales_dashboard_screen_1 = require("../screens/sales-dashboard/sales-dashboard.screen");
var sales_targets_screen_1 = require("../screens/sales-targets/sales-targets.screen");
var sales_targets_update_screen_1 = require("../screens/sales-targets/update/sales-targets-update.screen");
var product_sales_report_screen_1 = require("../screens/sales/product-sales-report.screen");
var shipping_charge_screen_1 = require("../screens/shipping-charge-by-zipcode/shipping-charge.screen");
var slider_create_screen_1 = require("../screens/slider/create/slider-create.screen");
var slider_list_screen_1 = require("../screens/slider/slider-list.screen");
var slider_update_screen_1 = require("../screens/slider/update/slider-update.screen");
var b2b_stock_income_create_screen_1 = require("../screens/b2b-stock-income/b2b-stock-income-create.screen");
var b2b_stock_income_list_screen_1 = require("../screens/b2b-stock-income/b2b-stock-income-list.screen");
var stock_rotation_report_screen_1 = require("../screens/stock-rotation-report/stock-rotation-report.screen");
var stock_shortage_subscriptions_screen_1 = require("../screens/stock-shortage-subscriptions/stock-shortage-subscriptions.screen");
var dpd_list_screen_1 = require("../screens/stock/dpd/dpd-list.screen");
var stock_history_screen_1 = require("../screens/stock/stock-history/stock-history.screen");
var stock_site_list_screen_1 = require("../screens/stock/stock-site/stock-site-list.screen");
var stock_storage_list_screen_1 = require("../screens/stock/stock-storage/stock-storage-list.screen");
var stock_change_details_screen_1 = require("../screens/stock/stock/stock-change-details.screen");
var stock_list_screen_1 = require("../screens/stock/stock/stock-list.screen");
var vam_informatika_list_screen_1 = require("../screens/stock/vam-informatika/vam-informatika-list.screen");
var supplier_partner_create_screen_1 = require("../screens/supplier-partner/supplier-partner-create-update/supplier-partner-create.screen");
var supplier_partner_update_screen_1 = require("../screens/supplier-partner/supplier-partner-create-update/supplier-partner-update.screen");
var supplier_partner_list_screen_1 = require("../screens/supplier-partner/supplier-partner-list.screen");
var supplier_stock_report_new_screen_1 = require("../screens/supplier-stock-report-new/supplier-stock-report-new.screen");
var supplier_stock_report_screen_1 = require("../screens/supplier-stock-report/supplier-stock-report.screen");
var synonym_dictionary_screen_1 = require("../screens/synonym-dictionary/synonym-dictionary.screen");
var test_screen_1 = require("../screens/test.screen");
var user_create_screen_1 = require("../screens/user/user-create/user-create.screen");
var user_list_screen_1 = require("../screens/user/user-list.screen");
var user_update_screen_1 = require("../screens/user/user-update/user-update.screen");
var change_log_screen_1 = require("../screens/change-log.screen");
var anyk_screen_1 = require("../screens/anyk.screen");
var customer_partner_report_screen_1 = require("../screens/customer-partner-report/customer-partner-report.screen");
var reports_manager_screen_2 = require("../screens/wp-orders-manager/reports-manager.screen");
var authorizedMiddlewares = [
    new authorized_middleware_1.AuthorizedMiddleware({
        redirectTo: '/login'
    }),
];
var unauthorizedMiddlewares = [
    new unauthorized_middleware_1.UnauthorizedMiddleware({
        redirectTo: '/login'
    }),
];
module.exports = [
    {
        middlewares: [],
        path: '/accept-invitation/:invitationToken/:userId',
        layout: login_layout_1.LoginLayout,
        component: accept_invitation_screen_1.AcceptInvitationScreen
    },
    {
        middlewares: unauthorizedMiddlewares,
        path: '/login',
        layout: login_layout_1.LoginLayout,
        component: login_screen_1.LoginScreen
    },
    {
        middlewares: [],
        path: '/forgot-password',
        layout: login_layout_1.LoginLayout,
        component: forgot_password_screen_1.ForgotPasswordScreen
    },
    {
        middlewares: [],
        path: '/recover-password',
        layout: login_layout_1.LoginLayout,
        component: recover_password_screen_1.RecoverPasswordScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.DASHBOARD.read] })], false),
        path: '/',
        layout: admin_layout_1.AdminLayout,
        component: dashboard_screen_1.DashboardScreen
    },
    {
        middlewares: [new redirect_middleware_1.RedirectMiddleware('/products')],
        path: '/',
        layout: admin_layout_1.AdminLayout,
        component: product_list_screen_1.ProductListScreen
    },
    {
        middlewares: authorizedMiddlewares,
        path: '/change-log',
        layout: admin_layout_1.AdminLayout,
        component: change_log_screen_1.ChangeLogScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.CRM.customers.read] })], false),
        path: '/customers',
        layout: admin_layout_1.AdminLayout,
        component: customer_list_screen_1.CustomerListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.CRM.coupons.read] })], false),
        path: '/coupons',
        layout: admin_layout_1.AdminLayout,
        component: coupon_list_screen_1.CouponListScreen
    },
    {
        middlewares: authorizedMiddlewares,
        path: '/bundle',
        layout: admin_layout_1.AdminLayout,
        component: bundle_list_screen_1.BundleListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.CRM.complaints.read] })], false),
        path: '/complaints',
        layout: admin_layout_1.AdminLayout,
        component: complaint_list_screen_1.ComplaintListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.CRM.complaints.read] })], false),
        path: '/complaints/:complaintId',
        layout: admin_layout_1.AdminLayout,
        component: complaint_details_screen_1.ComplaintDetailsScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.CRM.complaints.create] })], false),
        path: '/coupons/create',
        layout: admin_layout_1.AdminLayout,
        component: coupon_create_screen_1.CouponCreateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.CRM.complaints.read] })], false),
        path: '/coupons/:couponId',
        layout: admin_layout_1.AdminLayout,
        component: coupon_update_screen_1.CouponUpdateScreen
    },
    {
        middlewares: authorizedMiddlewares,
        path: '/bundle/create',
        layout: admin_layout_1.AdminLayout,
        component: bundle_create_screen_1.BundleCreateScreen
    },
    {
        middlewares: authorizedMiddlewares,
        path: '/bundle/:itemId',
        layout: admin_layout_1.AdminLayout,
        component: bundle_update_screen_1.BundleUpdateScreen
    },
    {
        middlewares: authorizedMiddlewares,
        path: '/blog',
        layout: admin_layout_1.AdminLayout,
        component: blog_screen_1.BlogScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.winery.read] })], false),
        path: '/winery',
        layout: admin_layout_1.AdminLayout,
        component: winery_list_screen_1.WineryListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.winery.create] })], false),
        path: '/winery/create',
        layout: admin_layout_1.AdminLayout,
        component: winery_create_screen_1.WineryCreateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.winery.read] })], false),
        path: '/winery/update/:wineryId',
        layout: admin_layout_1.AdminLayout,
        component: winery_update_screen_1.WineryUpdateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.PRODUCTS.read] })], false),
        path: '/products',
        layout: admin_layout_1.AdminLayout,
        component: product_list_screen_1.ProductListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.PRODUCTS.create] })], false),
        path: '/products/create',
        layout: admin_layout_1.AdminLayout,
        component: product_create_screen_1.ProductCreateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.PRODUCTS.read] })], false),
        path: '/products/:productId',
        layout: admin_layout_1.AdminLayout,
        component: product_update_screen_1.ProductUpdateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.ORDERS.b2cOrders.read] })], false),
        path: '/orders',
        layout: admin_layout_1.AdminLayout,
        component: order_list_screen_1.OrderListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.REPORTS.orderSalesReport.read] })], false),
        path: '/order-sales-report',
        layout: admin_layout_1.AdminLayout,
        component: order_sales_report_screen_1.OrderSalesReportScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.REPORTS.supplierStockReport.read] })], false),
        path: '/supplier-stock-report',
        layout: admin_layout_1.AdminLayout,
        component: supplier_stock_report_screen_1.SupplierStockReportScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.REPORTS.orderSalesReport.read] })], false),
        path: '/product-sales-by-orders-report',
        layout: admin_layout_1.AdminLayout,
        component: product_sales_by_orders_report_screen_1.ProductSalesReportByOrdersScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.REPORTS.supplierStockReport.read] })], false),
        path: '/supplier-stock-report-new',
        layout: admin_layout_1.AdminLayout,
        component: supplier_stock_report_new_screen_1.SupplierStockReportNewScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.REPORTS.productSalesReport.read] })], false),
        path: '/product-sales-report',
        layout: admin_layout_1.AdminLayout,
        component: product_sales_report_screen_1.ProductSalesReportScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.REPORTS.supplierStockReport.read] })], false),
        path: '/supplier-stock-report',
        layout: admin_layout_1.AdminLayout,
        component: supplier_stock_report_screen_1.SupplierStockReportScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.CRM.stockShortageSubscriptions.read] })], false),
        path: '/stock-shortage-subscriptions',
        layout: admin_layout_1.AdminLayout,
        component: stock_shortage_subscriptions_screen_1.StockShortageSubscriptionsScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.ORDERS.b2cOrders.read] })], false),
        path: '/orders/:orderId',
        layout: admin_layout_1.AdminLayout,
        component: order_details_screen_1.OrderDetailsScreen
    },
    {
        middlewares: authorizedMiddlewares,
        path: '/pricing',
        layout: admin_layout_1.AdminLayout,
        component: pricing_screen_1.PricingScreen
    },
    {
        middlewares: authorizedMiddlewares,
        path: '/user',
        layout: admin_layout_1.AdminLayout,
        component: user_list_screen_1.UserListScreen
    },
    {
        middlewares: authorizedMiddlewares,
        path: '/sales-targets',
        layout: admin_layout_1.AdminLayout,
        component: sales_targets_screen_1.SalesTargetsScreen
    },
    {
        middlewares: authorizedMiddlewares,
        path: '/shipping-charge',
        layout: admin_layout_1.AdminLayout,
        component: shipping_charge_screen_1.ShippingChargeScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.salesTargets.update] })], false),
        path: '/sales-targets/update/:userId',
        layout: admin_layout_1.AdminLayout,
        component: sales_targets_update_screen_1.SalesTargetsUpdateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.CRM.customerPartners.read] })], false),
        path: '/customer-partner',
        layout: admin_layout_1.AdminLayout,
        component: customer_partner_list_screen_1.CustomerPartnerListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.CRM.customerPartners.create] })], false),
        path: '/customer-partner/create',
        layout: admin_layout_1.AdminLayout,
        component: customer_partner_create_screen_1.CustomerPartnerCreateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.CRM.customerPartners.read] })], false),
        path: '/customer-partner/update/:partnerId',
        layout: admin_layout_1.AdminLayout,
        component: customer_partner_update_screen_1.CustomerPartnerUpdateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.CRM.supplierPartners.read] })], false),
        path: '/supplier-partner',
        layout: admin_layout_1.AdminLayout,
        component: supplier_partner_list_screen_1.SupplierPartnerListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.CRM.supplierPartners.create] })], false),
        path: '/supplier-partner/create',
        layout: admin_layout_1.AdminLayout,
        component: supplier_partner_create_screen_1.SupplierPartnerCreateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.CRM.supplierPartners.update, permissions_1.PERMISSIONS.CRM.supplierPartners.read] })], false),
        path: '/supplier-partner/update/:partnerId',
        layout: admin_layout_1.AdminLayout,
        component: supplier_partner_update_screen_1.SupplierPartnerUpdateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.actualStock.read] })], false),
        path: '/stock',
        layout: admin_layout_1.AdminLayout,
        component: stock_list_screen_1.StockListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.actualStock.update] })], false),
        path: '/stock/:productId',
        layout: admin_layout_1.AdminLayout,
        component: stock_change_details_screen_1.StockChangeDetailsScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [special_permissions_1.SPECIAL_PERMISSIONS.vamInformatics] })], false),
        path: '/vam-informatika',
        layout: admin_layout_1.AdminLayout,
        component: vam_informatika_list_screen_1.VamInformatikaListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [special_permissions_1.SPECIAL_PERMISSIONS.dpd] })], false),
        path: '/dpd',
        layout: admin_layout_1.AdminLayout,
        component: dpd_list_screen_1.DpdListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.stockHistory.read] })], false),
        path: '/stock-history',
        layout: admin_layout_1.AdminLayout,
        component: stock_history_screen_1.StockHistoryScreen
    },
    {
        middlewares: authorizedMiddlewares,
        path: '/stock-site',
        layout: admin_layout_1.AdminLayout,
        component: stock_site_list_screen_1.StockSiteListScreen
    },
    {
        middlewares: authorizedMiddlewares,
        path: '/stock-storage',
        layout: admin_layout_1.AdminLayout,
        component: stock_storage_list_screen_1.StockStorageListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.users.read] })], false),
        path: '/user/create',
        layout: admin_layout_1.AdminLayout,
        component: user_create_screen_1.UserCreateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.users.update] })], false),
        path: '/user/update/:userId',
        layout: admin_layout_1.AdminLayout,
        component: user_update_screen_1.UserUpdateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.attributes.read] })], false),
        path: '/attributes',
        layout: admin_layout_1.AdminLayout,
        component: attributes_list_screen_1.AttributesListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.categories.read] })], false),
        path: '/categories',
        layout: admin_layout_1.AdminLayout,
        component: category_list_screen_1.CategoryListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.categories.update] })], false),
        path: '/categories/:categoryId/category-attributes',
        layout: admin_layout_1.AdminLayout,
        component: category_attribute_update_screen_1.CategoryAttributeUpdateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.slider.create] })], false),
        path: '/slider/create',
        layout: admin_layout_1.AdminLayout,
        component: slider_create_screen_1.SliderCreateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.slider.update] })], false),
        path: '/slider/:slideId',
        layout: admin_layout_1.AdminLayout,
        component: slider_update_screen_1.SliderUpdateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.slider.read] })], false),
        path: '/slider',
        layout: admin_layout_1.AdminLayout,
        component: slider_list_screen_1.SliderListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SALES_DASHBOARD.read] })], false),
        path: '/sales-dashboard',
        layout: admin_layout_1.AdminLayout,
        component: sales_dashboard_screen_1.SalesDashboardScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.regions.read] })], false),
        path: '/region',
        layout: admin_layout_1.AdminLayout,
        component: region_screen_1.RegionScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.regions.create] })], false),
        path: '/region/create',
        layout: admin_layout_1.AdminLayout,
        component: region_create_screen_1.RegionCreateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.regions.update] })], false),
        path: '/region/update/:regionId',
        layout: admin_layout_1.AdminLayout,
        component: region_update_screen_1.RegionUpdateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.CRM.newsletterSubscriber.read] })], false),
        path: '/newsletter-subscriber',
        layout: admin_layout_1.AdminLayout,
        component: newsletter_subscriber_list_screen_1.NewsletterSubscriberListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.mainMenuSettings.read] })], false),
        path: '/main-menu-settings',
        layout: admin_layout_1.AdminLayout,
        component: main_menu_settings_screen_1.MainMenuSettingsScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.productOrders.read] })], false),
        path: '/product-orders',
        layout: admin_layout_1.AdminLayout,
        component: product_order_screen_1.ProductOrderScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.CRM.searchKeywords.read] })], false),
        path: '/search-keywords',
        layout: admin_layout_1.AdminLayout,
        component: synonym_dictionary_screen_1.SynonymDictionaryScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.attributes.create] })], false),
        path: '/attributes/create',
        layout: admin_layout_1.AdminLayout,
        component: attribute_create_screen_1.AttributeCreateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.attributes.update] })], false),
        path: '/attributes/update/:attributeId',
        layout: admin_layout_1.AdminLayout,
        component: attribute_update_screen_1.AttributeUpdateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.attributes.update] })], false),
        path: '/attributes/:attributeId/options',
        layout: admin_layout_1.AdminLayout,
        component: attributes_options_list_screen_1.AttributesOptionsListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.procurement.read] })], false),
        path: '/procurement',
        layout: admin_layout_1.AdminLayout,
        component: procurement_list_screen_1.ProcurementListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.procurement.create] })], false),
        path: '/procurement/create',
        layout: admin_layout_1.AdminLayout,
        component: procurement_create_screen_1.ProcurementCreateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.procurement.read] })], false),
        path: '/procurement/:id',
        layout: admin_layout_1.AdminLayout,
        component: procurement_view_screen_1.ProcurementViewScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.procurement.update] })], false),
        path: '/procurement/:id/update',
        layout: admin_layout_1.AdminLayout,
        component: procurement_update_screen_1.ProcurementUpdateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.b2bDepot.read] })], false),
        path: '/b2b-depot',
        layout: admin_layout_1.AdminLayout,
        component: b2b_depot_list_screen_1.B2bDepotListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.b2bDepot.create] })], false),
        path: '/b2b-depot/create',
        layout: admin_layout_1.AdminLayout,
        component: b2b_depot_create_screen_1.B2bDepotCreateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.b2bDepot.update] })], false),
        path: '/b2b-depot/update/:depotId',
        layout: admin_layout_1.AdminLayout,
        component: b2b_depot_update_screen_1.B2bDepotUpdateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.b2bWarehouse.update] })], false),
        path: '/b2b-warehouse',
        layout: admin_layout_1.AdminLayout,
        component: b2b_warehouse_list_screen_1.B2bWarehouseListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.b2bWarehouse.create] })], false),
        path: '/b2b-warehouse/create',
        layout: admin_layout_1.AdminLayout,
        component: b2b_warehouse_create_screen_1.B2bWarehouseCreateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.b2bWarehouse.update] })], false),
        path: '/b2b-warehouse/update/:warehouseId',
        layout: admin_layout_1.AdminLayout,
        component: b2b_warehouse_update_screen_1.B2bWarehouseUpdateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.b2bInventory.update] })], false),
        path: '/b2b-inventory',
        layout: admin_layout_1.AdminLayout,
        component: b2b_inventory_list_screen_1.B2bInventoryListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.b2bInventory.create] })], false),
        path: '/b2b-inventory/create',
        layout: admin_layout_1.AdminLayout,
        component: b2b_inventory_create_screen_1.B2bInventoryCreateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.b2bInventory.update] })], false),
        path: '/b2b-inventory/update/:inventoryId',
        layout: admin_layout_1.AdminLayout,
        component: b2b_inventory_update_screen_1.B2bInventoryUpdateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.b2bStorage.read] })], false),
        path: '/b2b-storage',
        layout: admin_layout_1.AdminLayout,
        component: b2b_storage_list_screen_1.B2bStorageListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.b2bStorage.create] })], false),
        path: '/b2b-storage/create',
        layout: admin_layout_1.AdminLayout,
        component: b2b_storage_create_screen_1.B2bStorageCreateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.b2bStorage.update] })], false),
        path: '/b2b-storage/update/:storageId',
        layout: admin_layout_1.AdminLayout,
        component: b2b_storage_update_screen_1.B2bStorageUpdateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.stockIncome.read] })], false),
        path: '/stock-income',
        layout: admin_layout_1.AdminLayout,
        component: b2b_stock_income_list_screen_1.B2bStockIncomeListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.stockIncome.create] })], false),
        path: '/stock-income/create',
        layout: admin_layout_1.AdminLayout,
        component: b2b_stock_income_create_screen_1.B2bStockIncomeCreateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.stockIncome.read] })], false),
        path: '/stock-income/:id',
        layout: admin_layout_1.AdminLayout,
        component: b2b_stock_income_update_show_screen_1.B2bStockIncomeUpdateShowScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.actualStock.read] })], false),
        path: '/b2b-stock',
        layout: admin_layout_1.AdminLayout,
        component: b2b_stock_screen_1.B2bStockScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.actualStock.read] })], false),
        path: '/b2b-kn-stock',
        layout: admin_layout_1.AdminLayout,
        component: b2b_kn_stock_screen_1.B2bKnStockScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.partnerStockReservation.read] })], false),
        path: '/customer-partner-stock-reservation',
        layout: admin_layout_1.AdminLayout,
        component: b2b_stock_reservation_customer_partner_list_screen_1.B2bStockReservationCustomerPartnerListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.partnerStockReservation.read] })], false),
        path: '/customer-partner-stock-reservation/create',
        layout: admin_layout_1.AdminLayout,
        component: b2b_stock_reservation_customer_partner_create_screen_1.B2bStockReservationCustomerPartnerCreateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.partnerStockReservation.read] })], false),
        path: '/customer-partner-stock-reservation/:id',
        layout: admin_layout_1.AdminLayout,
        component: b2b_stock_reservation_customer_partner_view_screen_1.B2bStockReservationCustomerPartnerViewScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.partnerStockReservation.update] })], false),
        path: '/customer-partner-stock-reservation/:id/release',
        layout: admin_layout_1.AdminLayout,
        component: b2b_stock_reservation_customer_partner_release_1.B2bStockReservationCustomerPartnerRelease
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.channelStockReservation.read] })], false),
        path: '/channel-stock-reservation',
        layout: admin_layout_1.AdminLayout,
        component: b2b_stock_reservation_channel_list_screen_1.B2bStockReservationChannelListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.channelStockReservation.create] })], false),
        path: '/channel-stock-reservation/create',
        layout: admin_layout_1.AdminLayout,
        component: b2b_stock_reservation_channel_create_screen_1.B2bStockReservationChannelCreateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.channelStockReservation.read] })], false),
        path: '/channel-stock-reservation/:id',
        layout: admin_layout_1.AdminLayout,
        component: b2b_stock_reservation_channel_view_screen_1.B2bStockReservationChannelViewScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.channelStockReservation.read] })], false),
        path: '/channel-stock-reservation/:id/release',
        layout: admin_layout_1.AdminLayout,
        component: b2b_stock_reservation_channel_release_1.B2bStockReservationChannelRelease
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.stockHistory.read] })], false),
        path: '/b2b-stock-history',
        layout: admin_layout_1.AdminLayout,
        component: b2b_stock_history_screen_1.B2bStockHistoryScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.stockDisposable.read] })], false),
        path: '/stock-disposal',
        layout: admin_layout_1.AdminLayout,
        component: b2b_stock_disposal_list_screen_1.B2bStockDisposalListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.stockDisposable.create] })], false),
        path: '/stock-disposal/create',
        layout: admin_layout_1.AdminLayout,
        component: b2b_stock_disposal_create_screen_1.B2bStockDisposalCreateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.stockDisposable.update] })], false),
        path: '/stock-disposal/:id',
        layout: admin_layout_1.AdminLayout,
        component: b2b_stock_disposal_view_screen_1.B2bStockDisposalViewScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.stockTransaction.read] })], false),
        path: '/stock-transaction',
        layout: admin_layout_1.AdminLayout,
        component: b2b_stock_transaction_list_screen_1.B2bStockTransactionListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.stockTransaction.create] })], false),
        path: '/stock-transaction/create',
        layout: admin_layout_1.AdminLayout,
        component: b2b_stock_transaction_create_screen_1.B2bStockTransactionCreateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.stockTransaction.read] })], false),
        path: '/stock-transaction/:id',
        layout: admin_layout_1.AdminLayout,
        component: b2b_stock_transaction_show_screen_1.B2bStockTransactionShowScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.stockIssue.create] })], false),
        path: '/stock-issuing/create',
        layout: admin_layout_1.AdminLayout,
        component: b2b_stock_issuing_create_update_screen_1.B2bStockIssuingCreateUpdateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.stockIssue.create] })], false),
        path: '/stock-issuing/:id',
        layout: admin_layout_1.AdminLayout,
        component: b2b_stock_issuing_create_update_screen_1.B2bStockIssuingCreateUpdateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.stockIssue.create] })], false),
        path: '/stock-issuing',
        layout: admin_layout_1.AdminLayout,
        component: b2b_stock_issuing_list_screen_1.B2bStockIssuingListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.anyk.read] })], false),
        path: '/anyk',
        layout: admin_layout_1.AdminLayout,
        component: anyk_screen_1.AnykScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.PRICING.supplierPricing.read] })], false),
        path: '/b2b-supplier-pricing',
        layout: admin_layout_1.AdminLayout,
        component: b2b_supplier_pricing_list_screen_1.B2bSupplierPricingListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.PRICING.channelPricing.read] })], false),
        path: '/b2b-channel-pricing',
        layout: admin_layout_1.AdminLayout,
        component: b2b_channel_pricing_list_screen_1.B2bChannelPricingListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.PRICING.customerPartnerPricing.read] })], false),
        path: '/b2b-customer-partner-pricing',
        layout: admin_layout_1.AdminLayout,
        component: b2b_customer_partner_pricing_list_screen_1.B2bCustomerPartnerPricingListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.ORDERS.b2bOrders.read] })], false),
        path: '/b2b-order',
        layout: admin_layout_1.AdminLayout,
        component: b2b_order_list_screen_1.B2bOrderListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.ORDERS.b2bOrders.create] })], false),
        path: '/b2b-order/create',
        layout: admin_layout_1.AdminLayout,
        component: b2b_order_create_screen_1.B2bOrderCreateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({
                roles: [
                    permissions_1.PERMISSIONS.ORDERS.b2bOrders.update,
                    permissions_1.PERMISSIONS.ORDERS.b2bOrders.read,
                ]
            })], false),
        path: '/b2b-order/:id',
        layout: admin_layout_1.AdminLayout,
        component: b2b_order_update_screen_1.B2bOrderUpdateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.stockProductInventory.read] })], false),
        path: '/stock-product-inventory',
        layout: admin_layout_1.AdminLayout,
        component: b2b_stock_inventory_list_screen_1.B2bStockInventoryListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.stockProductInventory.create] })], false),
        path: '/stock-product-inventory/create',
        layout: admin_layout_1.AdminLayout,
        component: b2b_stock_inventory_create_screen_1.B2bStockInventoryCreateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.stockProductInventory.update] })], false),
        path: '/stock-product-inventory/:id',
        layout: admin_layout_1.AdminLayout,
        component: b2b_stock_inventory_update_screen_1.B2bStockInventoryUpdateScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.REPORTS.stockRotationReport.read] })], false),
        path: '/stock-rotation-report',
        layout: admin_layout_1.AdminLayout,
        component: stock_rotation_report_screen_1.StockRotationReportScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.REPORTS.customerPartnerReport.read] })], false),
        path: '/customer-partner-report',
        layout: admin_layout_1.AdminLayout,
        component: customer_partner_report_screen_1.CustomerPartnerReportScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.STORAGE.actualStock.read] })], false),
        path: '/document-archives',
        layout: admin_layout_1.AdminLayout,
        component: document_archives_list_screen_1.DocumentArchivesListScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.REPORTS.customerPartnerReport.read] })], false),
        path: '/reports-manager',
        layout: admin_layout_1.AdminLayout,
        component: reports_manager_screen_1.ReportsManagerScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.REPORTS.customerPartnerReport.read] })], false),
        path: '/wp-orders-manager',
        layout: admin_layout_1.AdminLayout,
        component: reports_manager_screen_2.WpOrdersManager
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.knAttributes.read] })], false),
        path: '/kn-attributes',
        layout: admin_layout_1.AdminLayout,
        component: kn_attribute_list_screen_1.KnAttributeListScreen,
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.knAttributes.create] })], false),
        path: '/kn-attributes/create',
        layout: admin_layout_1.AdminLayout,
        component: kn_attribute_create_screen_1.KnAttributeCreateScreen,
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [permissions_1.PERMISSIONS.SETTINGS.knAttributes.update] })], false),
        path: '/kn-attributes/:id',
        layout: admin_layout_1.AdminLayout,
        component: kn_attribute_update_screen_1.KnAttributeUpdateScreen,
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [special_permissions_1.SPECIAL_PERMISSIONS.admin] })], false),
        path: '(/test)',
        layout: admin_layout_1.AdminLayout,
        component: test_screen_1.TestScreen
    },
    {
        middlewares: __spreadArray(__spreadArray([], __read(authorizedMiddlewares), false), [new permission_middleware_1.PermissionMiddleware({ roles: [special_permissions_1.SPECIAL_PERMISSIONS.admin] })], false),
        path: '(.*)',
        layout: admin_layout_1.AdminLayout,
        component: not_found_screen_1.NotFoundScreen
    },
];

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StockIncomeTableRow = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var lodash_1 = require("lodash");
var React = require("react");
var single_storage_tree_select_form_control_type_1 = require("../../../common/components/tree-select/single-storage-tree-select.form-control-type");
var tree_select_1 = require("../../../common/components/tree-select/tree-select");
var modal_1 = require("../../../components/modal/modal");
var select_storage_modal_1 = require("./select-storage.modal");
var StockIncomeTableRow = (function (_super) {
    __extends(StockIncomeTableRow, _super);
    function StockIncomeTableRow() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                storage: new field_1.Field({
                    name: 'storage',
                    id: 'storage',
                    value: null,
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                lot: new field_1.Field({
                    name: 'lot',
                    id: 'lot',
                    value: null,
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-income.create.form.lot.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                amount: new field_1.Field({
                    name: 'amount',
                    id: 'amount',
                    value: null,
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('stock-income.create.form.amount.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
            }
        });
        _this.state = {
            product: _this.props.product || {},
            warehouse: _this.props.warehouse || {},
            storageError: false,
            knCode: ''
        };
        return _this;
    }
    StockIncomeTableRow.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.form.fields.storage.setValue(this.props.product.storageId);
                        this.form.fields.lot.setValue(this.props.product.lot);
                        this.form.fields.amount.setValue(this.props.product.amount);
                        return [4, this.fetchKnCode()];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    StockIncomeTableRow.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        if (!(0, lodash_1.isEqual)(this.props.product, prevProps.product)) {
            this.setState({ product: this.props.product });
            this.form.fields.storage.setValue((_c = (_b = (_a = this.props.values) === null || _a === void 0 ? void 0 : _a.storage) === null || _b === void 0 ? void 0 : _b.value) !== null && _c !== void 0 ? _c : this.props.product.storageId);
            this.form.fields.lot.setValue((_f = (_e = (_d = this.props.values) === null || _d === void 0 ? void 0 : _d.lot) === null || _e === void 0 ? void 0 : _e.value) !== null && _f !== void 0 ? _f : this.props.product.lot);
            this.form.fields.amount.setValue((_j = (_h = (_g = this.props.values) === null || _g === void 0 ? void 0 : _g.amount) === null || _h === void 0 ? void 0 : _h.value) !== null && _j !== void 0 ? _j : this.props.product.amount);
        }
    };
    StockIncomeTableRow.prototype.fetchKnCode = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var knCode, knCodeResponse, e_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 2, , 3]);
                        knCode = ((_a = this.state.product.product) === null || _a === void 0 ? void 0 : _a.knCode) || ((_c = (_b = this.state.product) === null || _b === void 0 ? void 0 : _b.productArtifact) === null || _c === void 0 ? void 0 : _c.knCode);
                        if (!knCode) {
                            return [2];
                        }
                        return [4, this.repository.get("/admin/kn-attribute/".concat(knCode))];
                    case 1:
                        knCodeResponse = _d.sent();
                        this.setState({ knCode: (knCodeResponse === null || knCodeResponse === void 0 ? void 0 : knCodeResponse.knCode) || '-' });
                        return [3, 3];
                    case 2:
                        e_1 = _d.sent();
                        console.log({ fetchKnCode: e_1 });
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    StockIncomeTableRow.prototype.render = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
        var ean = ((_a = this.state.product.product) === null || _a === void 0 ? void 0 : _a.eanCode) || ((_b = this.state.product) === null || _b === void 0 ? void 0 : _b.ean) || '-';
        var sku = ((_c = this.state.product.product) === null || _c === void 0 ? void 0 : _c.sku) || ((_e = (_d = this.state.product) === null || _d === void 0 ? void 0 : _d.productArtifact) === null || _e === void 0 ? void 0 : _e.sku) || '-';
        var knCode = this.state.knCode;
        var manufacturer = ((_g = (_f = this.state.product.product) === null || _f === void 0 ? void 0 : _f.manufacturerName) === null || _g === void 0 ? void 0 : _g.hu) || ((_j = (_h = this.state.product) === null || _h === void 0 ? void 0 : _h.productArtifact) === null || _j === void 0 ? void 0 : _j.manufacturer) || '-';
        var title = ((_l = (_k = this.state.product.product) === null || _k === void 0 ? void 0 : _k.title) === null || _l === void 0 ? void 0 : _l.hu) || ((_o = (_m = this.state.product) === null || _m === void 0 ? void 0 : _m.productArtifact) === null || _o === void 0 ? void 0 : _o.title) || '-';
        var vintage = ((_p = this.state.product.product) === null || _p === void 0 ? void 0 : _p.vintage) || ((_r = (_q = this.state.product) === null || _q === void 0 ? void 0 : _q.productArtifact) === null || _r === void 0 ? void 0 : _r.vintage) || '-';
        var capacity = ((_u = (_t = (_s = this.state.product.product) === null || _s === void 0 ? void 0 : _s.b2cAttributeSearchValues) === null || _t === void 0 ? void 0 : _t.capacity) === null || _u === void 0 ? void 0 : _u[1]) || ((_v = this.state.product.productArtifact) === null || _v === void 0 ? void 0 : _v.capacity) || '-';
        return React.createElement(React.Fragment, null,
            this.renderModals(),
            React.createElement(table_body_row_1.TableBodyRow, { key: this.state.product.productId },
                React.createElement(table_body_cell_1.TableBodyCell, { width: 25 },
                    React.createElement("div", null,
                        React.createElement("p", null,
                            React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "EAN"),
                            React.createElement("span", null, ean)),
                        React.createElement("p", null,
                            React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "SKU"),
                            React.createElement("span", null, sku)),
                        React.createElement("p", null,
                            React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "KN K\u00D3D"),
                            React.createElement("span", null, knCode)),
                        React.createElement("p", null,
                            manufacturer,
                            " ",
                            title,
                            " ",
                            vintage,
                            " (",
                            capacity,
                            ")"))),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 40 },
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.storage, type: single_storage_tree_select_form_control_type_1.SingleStorageTreeSelectFormControlType },
                        React.createElement(tree_select_1.TreeSelect, { onlyAllowedStorages: false, readOnly: this.props.disabled, optionsNarrowing: (_w = this.state.warehouse) === null || _w === void 0 ? void 0 : _w._id, multi: false, enabledTypes: ['storage'], modalTitle: 'Tárhely választása', searchable: true, className: 'text-input-basic' }))),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.lot, type: text_input_form_control_type_1.TextInputFormControlType },
                        React.createElement(text_input_1.TextInput, { disabled: this.props.disabled, className: 'text-input-basic' }))),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.amount, type: text_input_form_control_type_1.TextInputFormControlType },
                        React.createElement(text_input_1.TextInput, { disabled: this.props.disabled, className: 'text-input-basic', type: 'number', min: 1 }))),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 5 }, this.deleteButton(this.state.product.productId))));
    };
    StockIncomeTableRow.prototype.getData = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var errors, hasError;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4, this.form.validate()];
                    case 1:
                        _d.sent();
                        return [4, ((_a = this.form) === null || _a === void 0 ? void 0 : _a.getErrors())];
                    case 2:
                        errors = _d.sent();
                        hasError = false;
                        (0, lodash_1.map)(errors, function (e) {
                            hasError = hasError || !!e.length;
                        });
                        return [2, __assign(__assign({}, this.form.toJSON()), { product: (_c = (_b = this.state.product) === null || _b === void 0 ? void 0 : _b.product) === null || _c === void 0 ? void 0 : _c._id })];
                }
            });
        });
    };
    StockIncomeTableRow.prototype.deleteButton = function (productId) {
        var _this = this;
        return React.createElement(button_1.Button, { className: 'delete-button', onClick: function () { return _this.props.showDeleteProductModal(productId); }, iconLeft: 'fas fa-trash' });
    };
    StockIncomeTableRow.prototype.renderModals = function () {
        var _this = this;
        return React.createElement(React.Fragment, null,
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$showSelectStorageModal = ref); }, renderModalHeader: function () { return React.createElement("h4", null, (0, trans_1.trans)('stock-income.select-storage.modal.title')); }, renderModalContent: function (ctx) { return React.createElement(select_storage_modal_1.SelectStorageModal, { storage: _this.state.storage, onClose: function () { return _this.$showSelectStorageModal.close(); }, onStorageSelect: function (storage) {
                        _this.form.fields.storage.setValue(storage._id);
                        _this.setState({ storage: storage });
                    } }); } }));
    };
    return StockIncomeTableRow;
}(abstract_component_1.AbstractComponent));
exports.StockIncomeTableRow = StockIncomeTableRow;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentArchivesListScreen = exports.ArchiveType = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var moment = require("moment");
var React = require("react");
var list_date_range_filter_component_1 = require("../../common/components/list-search/list-date-range-filter.component");
var list_search_component_1 = require("../../common/components/list-search/list-search.component");
var pagination_list_control_1 = require("../../common/components/repository-list/controls/pagination-list-control");
var pagination_control_1 = require("../../common/components/repository-list/controls/pagination.control");
var list_empty_1 = require("../../common/components/repository-list/empty/list-empty");
var list_loader_1 = require("../../common/components/repository-list/loader/list-loader");
var repository_list_1 = require("../../common/components/repository-list/repository-list");
var form_1 = require("../../components/form/form");
var ArchiveType;
(function (ArchiveType) {
    ArchiveType["dailyStockCSV"] = "daily-stock-csv";
    ArchiveType["dailyStockPDF"] = "daily-stock-pdf";
})(ArchiveType = exports.ArchiveType || (exports.ArchiveType = {}));
var DocumentArchivesListScreen = (function (_super) {
    __extends(DocumentArchivesListScreen, _super);
    function DocumentArchivesListScreen() {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                limit: 20,
                page: (_c = (_b = (_a = _this.props.route) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b._page) !== null && _c !== void 0 ? _c : 0,
                query: (_f = (_e = (_d = _this.props.route) === null || _d === void 0 ? void 0 : _d.query) === null || _e === void 0 ? void 0 : _e.query) !== null && _f !== void 0 ? _f : '',
                sort: (_h = (_g = _this.props.route) === null || _g === void 0 ? void 0 : _g.sort) !== null && _h !== void 0 ? _h : { createdAt: -1 }
            },
        });
        _this.filterForm = new form_builder_1.FormBuilder({
            fields: {
                type: new field_1.Field({
                    placeholder: '',
                    value: ((_k = (_j = _this.control) === null || _j === void 0 ? void 0 : _j.getQuery()) === null || _k === void 0 ? void 0 : _k.type) || '',
                    name: 'type',
                    label: (0, trans_1.trans)('document-archives.list.type.label'),
                    optionsEndpoint: '/admin/document-archives/types',
                    optionsMap: function (response) { return response.map(function (type) { return ({ key: type, value: type, name: (0, trans_1.trans)("document-archives.type.".concat(type)) }); }); },
                    loadOptionsAfterMount: true,
                    validators: []
                }),
            }
        });
        _this.state = {
            total: 0
        };
        return _this;
    }
    DocumentArchivesListScreen.prototype.componentDidMount = function () {
        var _this = this;
        this.$subscriptions.push(this.filterForm.fields.type.$value.subscribe(function (v) { return _this.handleChange('type', v); }));
    };
    DocumentArchivesListScreen.prototype.handleChange = function (property, value) {
        this.control.set(property, value);
        this.control.page(0);
    };
    DocumentArchivesListScreen.prototype.render = function () {
        var _this = this;
        return React.createElement("div", null,
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-15" },
                    React.createElement("div", { className: 'display-flex align-items-center mb-8' },
                        React.createElement("h2", { className: 'mr-4' }, (0, trans_1.trans)('document-archives.list.main.title')),
                        React.createElement("span", null,
                            this.state.total,
                            " db"))),
                React.createElement("div", { className: "col-9" },
                    React.createElement(list_search_component_1.ListSearchComponent, { "control$": this.control }))),
            React.createElement("div", { className: 'row mt-2' },
                React.createElement("div", { className: 'col' },
                    React.createElement("div", { className: 'filter-wrapper' },
                        React.createElement(form_1.Form, null,
                            React.createElement("div", { className: 'row mx-0' },
                                React.createElement(form_control_1.FormControl, { field: this.filterForm.fields.type, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { searchable: false, unclearable: false, tabIndex: 3, className: 'select-input-basic col-24 col-lg-8 pl-0' })),
                                React.createElement("div", { className: "col-16" },
                                    React.createElement(list_date_range_filter_component_1.ListDateRangeFilterComponent, { startDate: moment().add(-1, 'month').format('YYYY-MM-DD'), "control$": this.control }))))))),
            React.createElement(repository_list_1.RepositoryList, { control: this.control, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, defaultSort: { createdAt: 1 }, onChange: function (ctx) { return _this.handleListChange(ctx); }, repository: repository_1.Repository.use('default'), endpoint: '/admin/document-archives', renderItemsWrapper: function (ctx) { return _this.renderTable(ctx); }, renderItem: function (ctx, item, index) { return _this.renderItem(ctx, item); }, renderEmpty: function () { return (React.createElement(list_empty_1.ListEmpty, null)); }, renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, null)); } }),
            React.createElement(pagination_control_1.PaginationControl, { "control$": this.control, limitOptions: [
                    { name: 10, value: 10 },
                    { name: 20, value: 20 },
                    { name: 50, value: 50 },
                    { name: 100, value: 100 },
                ] }));
    };
    DocumentArchivesListScreen.prototype.renderTable = function (ctx) {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return ctx.renderItems(ctx); } });
    };
    DocumentArchivesListScreen.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: false },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 50 }, this.setHeaderCell('type'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 40 }, this.setHeaderCell('createdAt'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell())));
    };
    DocumentArchivesListScreen.prototype.setHeaderCell = function (p) {
        var _this = this;
        return {
            sortValue: this.getSortValue(p),
            onSort: function (property, value) { return _this.handleSort(property, value); },
            enableSort: !!p,
            title: p ? (0, trans_1.trans)("document-archives.list.".concat(p)) : '',
            property: p
        };
    };
    DocumentArchivesListScreen.prototype.handleSort = function (property, value) {
        this.control.sort(property, value);
    };
    DocumentArchivesListScreen.prototype.getSortValue = function (property) {
        var _a;
        return ((_a = this.control.data.sort) === null || _a === void 0 ? void 0 : _a[property]) || 0;
    };
    DocumentArchivesListScreen.prototype.renderItem = function (ctx, item) {
        return React.createElement(table_body_row_1.TableBodyRow, { key: item.fileURL },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 50, className: 'cursor-pointer' },
                React.createElement("span", null, (0, trans_1.trans)("document-archives.type.".concat(item.type)))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 45 },
                React.createElement("span", null, moment(item === null || item === void 0 ? void 0 : item.createdAt).format('YYYY.MM.DD.'))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15, className: 'cursor-pointer' },
                React.createElement(button_1.Button, { className: 'button-primary-outline button-size-normal', title: 'Letölt', iconLeft: 'far fa-download', onClick: function () { return window.open(item.fileURL); } })));
    };
    DocumentArchivesListScreen.prototype.handleListChange = function (ctx) {
        var _a;
        this.setState({ total: (_a = ctx.state) === null || _a === void 0 ? void 0 : _a.total });
    };
    return DocumentArchivesListScreen;
}(abstract_component_1.AbstractComponent));
exports.DocumentArchivesListScreen = DocumentArchivesListScreen;

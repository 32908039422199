"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnykScreen = void 0;
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var React = require("react");
var react_toastify_1 = require("react-toastify");
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var file_saver_1 = require("file-saver");
var JSZip = require("jszip");
var moment = require("moment");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var datepicker_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/datepicker.form-control-type");
var datepicker_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/datepicker/datepicker");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var AnykScreen = (function (_super) {
    __extends(AnykScreen, _super);
    function AnykScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.j28ExportForm = new form_builder_1.FormBuilder({
            fields: {
                from: new field_1.Field({
                    placeholder: '',
                    value: moment().format('YYYY-MM-DD'),
                    name: 'from',
                    label: React.createElement("div", null,
                        "D\u00E1tum ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
            }
        });
        _this.j09ExportForm = new form_builder_1.FormBuilder({
            fields: {
                from: new field_1.Field({
                    placeholder: '',
                    value: moment().format('YYYY-MM-DD'),
                    name: 'from',
                    label: React.createElement("div", null,
                        "-t\u00F3l ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                to: new field_1.Field({
                    placeholder: '',
                    value: moment().format('YYYY-MM-DD'),
                    name: 'to',
                    label: React.createElement("div", null,
                        "-ig ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
            }
        });
        _this.commonForm = new form_builder_1.FormBuilder({
            fields: {
                bejadoaz: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'bejadoaz',
                    label: React.createElement("div", null,
                        "Bejelent\u0151 ad\u00F3azonos\u00EDt\u00F3 ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                taxCode: new field_1.Field({
                    placeholder: '',
                    value: 'O1',
                    options: [
                        { key: 'N2', value: 'N2', name: 'N2' },
                        { key: 'N3', value: 'N3', name: 'N3' },
                        { key: 'O1', value: 'O1', name: 'O1' },
                        { key: '02', value: '02', name: '02' },
                        { key: 'P1', value: 'P1', name: 'P1' }
                    ],
                    name: 'taxCode',
                    label: React.createElement("div", null,
                        "Ad\u00F3m\u00E9rt\u00E9k k\u00F3d",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                })
            }
        });
        _this.state = { isDownloading: false };
        return _this;
    }
    AnykScreen.prototype.render = function () {
        return React.createElement("div", null,
            React.createElement("div", { className: "row mb-8" },
                React.createElement("div", { className: "col-24" }, this.renderCommonForm())),
            React.createElement("div", { className: "row mb-8" },
                React.createElement("div", { className: "col-24" }, this.renderJ28XMLExport())),
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-24" }, this.renderJ09XMLExport())));
    };
    AnykScreen.prototype.renderCommonForm = function () {
        return React.createElement("div", null,
            React.createElement("div", { className: 'row display-flex align-items-center' },
                React.createElement("div", { className: "col-6" },
                    React.createElement(form_control_1.FormControl, { field: this.commonForm.fields.bejadoaz, type: text_input_form_control_type_1.TextInputFormControlType },
                        React.createElement(text_input_1.TextInput, { type: 'text', className: 'text-input-basic' }))),
                React.createElement("div", { className: "col-6" },
                    React.createElement(form_control_1.FormControl, { field: this.commonForm.fields.taxCode, type: select_input_form_control_type_1.SelectInputFormControlType },
                        React.createElement(select_input_1.SelectInput, { className: 'select-input-basic' })))));
    };
    AnykScreen.prototype.renderJ28XMLExport = function () {
        var _this = this;
        return React.createElement("div", null,
            React.createElement("h4", { className: 'mb-4' }, "Adat export\u00E1l\u00E1s NAV_J28-as nyomtatv\u00E1nyhoz"),
            React.createElement("div", { className: 'row display-flex align-items-center' },
                React.createElement("div", { className: "col-6" },
                    React.createElement(form_control_1.FormControl, { field: this.j28ExportForm.fields.from, type: datepicker_form_control_type_1.DatepickerFormControlType },
                        React.createElement(datepicker_1.Datepicker, { type: 'date', locale: 'hu', className: 'datepicker-basic' }))),
                React.createElement("div", { className: 'col-6' },
                    React.createElement(button_1.Button, { disabled: this.state.isDownloading, className: 'button-primary-normal button-size-normal', title: 'Letöltés', onClick: function () { return _this.downloadJ28(); } }))));
    };
    AnykScreen.prototype.renderJ09XMLExport = function () {
        var _this = this;
        return React.createElement("div", null,
            React.createElement("h4", { className: 'mb-4' }, "Adat export\u00E1l\u00E1s NAV_J09-as nyomtatv\u00E1nyhoz"),
            React.createElement("div", { className: 'row display-flex align-items-center' },
                React.createElement("div", { className: "col-6" },
                    React.createElement(form_control_1.FormControl, { field: this.j09ExportForm.fields.from, type: datepicker_form_control_type_1.DatepickerFormControlType },
                        React.createElement(datepicker_1.Datepicker, { type: 'date', locale: 'hu', className: 'datepicker-basic' })),
                    React.createElement(form_control_1.FormControl, { field: this.j09ExportForm.fields.to, type: datepicker_form_control_type_1.DatepickerFormControlType },
                        React.createElement(datepicker_1.Datepicker, { type: 'date', locale: 'hu', className: 'datepicker-basic' }))),
                React.createElement("div", { className: 'col-6' },
                    React.createElement(button_1.Button, { disabled: this.state.isDownloading, className: 'button-primary-normal button-size-normal', title: 'Letöltés', onClick: function () { return _this.downloadJ09(); } }))));
    };
    AnykScreen.prototype.test = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.repository.get('/b2b/anyk/navj28')];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    AnykScreen.prototype.downloadJ28 = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var commonFormValid, valid, commonFormData, zip, date, data_1, _c, _d, d, error_1;
            var e_1, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        _f.trys.push([0, 8, , 10]);
                        return [4, this.setState({ isDownloading: true })];
                    case 1:
                        _f.sent();
                        return [4, this.commonForm.validate()];
                    case 2:
                        commonFormValid = _f.sent();
                        return [4, this.j28ExportForm.validate()];
                    case 3:
                        valid = _f.sent();
                        if (!(!valid || !commonFormValid)) return [3, 5];
                        return [4, this.setState({ isDownloading: false })];
                    case 4:
                        _f.sent();
                        return [2];
                    case 5:
                        commonFormData = this.commonForm.toJSON();
                        zip = new JSZip();
                        date = ((_a = this.j28ExportForm.toJSON()) === null || _a === void 0 ? void 0 : _a.from) || moment().format('YYYY-MM-DD');
                        return [4, this.repository.get('/b2b/anyk/navj28', { params: { date: moment(date).format('YYYYMMDD'), taxCode: commonFormData.taxCode, bejadoaz: commonFormData.bejadoaz } })];
                    case 6:
                        data_1 = _f.sent();
                        if (!((_b = data_1.forms) === null || _b === void 0 ? void 0 : _b.length)) {
                            throw new Error('Nincs a nyomtatványhoz szükséges adat a megjelölt dátumtól');
                        }
                        try {
                            for (_c = __values(data_1.forms), _d = _c.next(); !_d.done; _d = _c.next()) {
                                d = _d.value;
                                zip.file("".concat(d.warehouse, ".xml"), d.xml);
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (_d && !_d.done && (_e = _c.return)) _e.call(_c);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        zip.generateAsync({ type: 'blob' })
                            .then(function (content) {
                            (0, file_saver_1.saveAs)(content, "J28-".concat(moment(data_1.from).format('YYYYMMDD'), ".zip"));
                        });
                        return [4, this.setState({ isDownloading: false })];
                    case 7:
                        _f.sent();
                        return [3, 10];
                    case 8:
                        error_1 = _f.sent();
                        return [4, this.setState({ isDownloading: false })];
                    case 9:
                        _f.sent();
                        return [2, react_toastify_1.toast.error(error_1.message, { icon: function () { return React.createElement('i', { className: 'far fa-exclamation-circle color--error-6' }); } })];
                    case 10: return [2];
                }
            });
        });
    };
    AnykScreen.prototype.downloadJ09 = function () {
        return __awaiter(this, void 0, void 0, function () {
            var valid, zip, date, data_2, _a, _b, d, e_2;
            var e_3, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 7, , 9]);
                        return [4, this.setState({ isDownloading: true })];
                    case 1:
                        _d.sent();
                        return [4, this.j09ExportForm.validate()];
                    case 2:
                        valid = _d.sent();
                        if (!!valid) return [3, 4];
                        return [4, this.setState({ isDownloading: false })];
                    case 3:
                        _d.sent();
                        return [2];
                    case 4:
                        zip = new JSZip();
                        date = this.j09ExportForm.toJSON() || moment().format('YYYY-MM-DD');
                        return [4, this.repository.get('/b2b/anyk/navj09', { params: { from: moment(date.from).format('YYYYMMDD'), to: moment(date.to).format('YYYYMMDD') } })];
                    case 5:
                        data_2 = _d.sent();
                        try {
                            for (_a = __values(data_2.forms), _b = _a.next(); !_b.done; _b = _a.next()) {
                                d = _b.value;
                                zip.file("J09-".concat(d.warehouse, ".xml"), d.xml);
                                zip.file("J09-".concat(d.warehouse, ".vny"), d.vny);
                            }
                        }
                        catch (e_3_1) { e_3 = { error: e_3_1 }; }
                        finally {
                            try {
                                if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                            }
                            finally { if (e_3) throw e_3.error; }
                        }
                        zip.generateAsync({ type: 'blob' })
                            .then(function (content) {
                            (0, file_saver_1.saveAs)(content, "J09-".concat(moment(data_2.from).format('YYYYMMDD'), "_").concat(moment(data_2.to).format('YYYYMMDD'), ".zip"));
                        });
                        return [4, this.setState({ isDownloading: false })];
                    case 6:
                        _d.sent();
                        return [3, 9];
                    case 7:
                        e_2 = _d.sent();
                        return [4, this.setState({ isDownloading: false })];
                    case 8:
                        _d.sent();
                        return [3, 9];
                    case 9: return [2];
                }
            });
        });
    };
    return AnykScreen;
}(abstract_component_1.AbstractComponent));
exports.AnykScreen = AnykScreen;

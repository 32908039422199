"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PriceModifierForm = void 0;
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var lodash_1 = require("lodash");
var React = require("react");
var price_modifier_by_category_1 = require("./price-modifier-by-category");
var PriceModifierForm = (function (_super) {
    __extends(PriceModifierForm, _super);
    function PriceModifierForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            priceModifiers: {}
        };
        return _this;
    }
    PriceModifierForm.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (!(0, lodash_1.isEqual)(prevState.priceModifiers, this.state.priceModifiers)) {
            this.props.onChange(this.state.priceModifiers);
        }
    };
    PriceModifierForm.prototype.render = function () {
        var _this = this;
        var priceModifiers = Object.entries(this.props.priceModifiers);
        return React.createElement("div", { className: 'row general-price-modifier-box' },
            React.createElement("div", { className: 'col-24' },
                React.createElement("h5", { className: 'mb-4 text-left' }, "Partnerkedvezm\u00E9nyek term\u00E9kkateg\u00F3ri\u00E1nk\u00E9nt")),
            React.createElement("div", { className: 'col-24 row' }, priceModifiers.map(function (_a) {
                var _b = __read(_a, 2), categoryId = _b[0], priceModifier = _b[1];
                return React.createElement(price_modifier_by_category_1.PriceModifierByCategory, { key: categoryId, priceModifier: priceModifier, readOnly: _this.props.readOnly, onChange: function (modifier) {
                        var _a;
                        return _this.setState({ priceModifiers: __assign(__assign({}, _this.state.priceModifiers), (_a = {}, _a[categoryId] = { modifier: modifier }, _a)) });
                    } });
            })));
    };
    return PriceModifierForm;
}(abstract_component_1.AbstractComponent));
exports.PriceModifierForm = PriceModifierForm;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StockTransactionCreateTableRow = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var lodash_1 = require("lodash");
var React = require("react");
var single_storage_tree_select_form_control_type_1 = require("../../../common/components/tree-select/single-storage-tree-select.form-control-type");
var storage_by_product_tree_select_form_control_type_1 = require("../../../common/components/tree-select/storage-by-product-tree-select.form-control-type");
var tree_select_1 = require("../../../common/components/tree-select/tree-select");
var form_1 = require("../../../components/form/form");
require("../b2b-stock-transaction.scss");
var stock_transaction_create_table_stock_product_row_1 = require("./stock-transaction-create-table-stock-product-row");
var StockTransactionCreateTableRow = (function (_super) {
    __extends(StockTransactionCreateTableRow, _super);
    function StockTransactionCreateTableRow() {
        var _this = this;
        var _a;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.stockProductsRefs = {};
        _this.state = {
            product: _this.props.product || {},
            fromStorage: null,
            stockProducts: {},
            openedRows: [],
            isFromStorageSelected: false,
        };
        _this.repository = repository_1.Repository.use('default');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                product: new field_1.Field({
                    name: 'product',
                    id: 'product',
                    value: (_a = _this.state.product.product) === null || _a === void 0 ? void 0 : _a._id,
                }),
                fromStorage: new field_1.Field({
                    name: 'fromStorage',
                    id: 'fromStorage',
                    value: null,
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                toStorage: new field_1.Field({
                    name: 'toStorage',
                    id: 'toStorage',
                    value: null,
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
            }
        });
        return _this;
    }
    StockTransactionCreateTableRow.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.$subscriptions.push(this.form.fields.fromStorage.$value.subscribe(function (v) { return _this.handleFromStorageChange(v); }));
                return [2];
            });
        });
    };
    StockTransactionCreateTableRow.prototype.handleFromStorageChange = function (value) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var result, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!value) {
                            return [2, this.setState({
                                    stockProducts: [],
                                    isFromStorageSelected: false,
                                    fromStorage: null
                                })];
                        }
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        return [4, this.repository.get("/b2b/stock/get-by-product-and-storage/".concat((_b = (_a = this.state.product) === null || _a === void 0 ? void 0 : _a.product) === null || _b === void 0 ? void 0 : _b._id, "/").concat(value))];
                    case 2:
                        result = _c.sent();
                        this.setState({
                            stockProducts: this.getLotData(result),
                            isFromStorageSelected: true,
                            fromStorage: value
                        });
                        return [3, 4];
                    case 3:
                        e_1 = _c.sent();
                        this.setState({ error: e_1.message });
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    StockTransactionCreateTableRow.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10;
        var ean = ((_a = this.state.product.product) === null || _a === void 0 ? void 0 : _a.eanCode) || ((_b = this.state.product) === null || _b === void 0 ? void 0 : _b.ean) || '-';
        var sku = ((_c = this.state.product.product) === null || _c === void 0 ? void 0 : _c.sku) || ((_e = (_d = this.state.product) === null || _d === void 0 ? void 0 : _d.productArtifact) === null || _e === void 0 ? void 0 : _e.sku) || '-';
        var drs = ((_f = this.state.product.product) === null || _f === void 0 ? void 0 : _f.drs) || ((_h = (_g = this.state.product) === null || _g === void 0 ? void 0 : _g.productArtifact) === null || _h === void 0 ? void 0 : _h.drs) ? '(DRS)' : '';
        var drs2 = ((_j = this.state.product.product) === null || _j === void 0 ? void 0 : _j.drs2) || ((_l = (_k = this.state.product) === null || _k === void 0 ? void 0 : _k.productArtifact) === null || _l === void 0 ? void 0 : _l.drs2) ? '(VISSZAVÁLTHATÓ ÜVEG)' : '';
        var drsText = drs || drs2;
        var manufacturer = ((_o = (_m = this.state.product.product) === null || _m === void 0 ? void 0 : _m.manufacturerName) === null || _o === void 0 ? void 0 : _o.hu) || ((_q = (_p = this.state.product) === null || _p === void 0 ? void 0 : _p.productArtifact) === null || _q === void 0 ? void 0 : _q.manufacturer) || '-';
        var title = ((_s = (_r = this.state.product.product) === null || _r === void 0 ? void 0 : _r.title) === null || _s === void 0 ? void 0 : _s.hu) || ((_u = (_t = this.state.product) === null || _t === void 0 ? void 0 : _t.productArtifact) === null || _u === void 0 ? void 0 : _u.title) || '-';
        var vintage = ((_v = this.state.product.product) === null || _v === void 0 ? void 0 : _v.vintage) || ((_x = (_w = this.state.product) === null || _w === void 0 ? void 0 : _w.productArtifact) === null || _x === void 0 ? void 0 : _x.vintage) || '-';
        var capacity = ((_0 = (_z = (_y = this.state.product.product) === null || _y === void 0 ? void 0 : _y.b2cAttributeSearchValues) === null || _z === void 0 ? void 0 : _z.capacity) === null || _0 === void 0 ? void 0 : _0[1]) || ((_1 = this.state.product.productArtifact) === null || _1 === void 0 ? void 0 : _1.capacity) || '-';
        return React.createElement(form_1.Form, { className: 'StockTransactionCreateTableRow' },
            React.createElement(table_body_row_1.TableBodyRow, { className: 'flex-column wrapper main-wrapper' },
                React.createElement(table_body_cell_1.TableBodyCell, { width: 100 },
                    React.createElement(table_body_row_1.TableBodyRow, { key: this.state.product._id, className: 'cursor-pointer align-items-center content', onClick: function () { var _a; return _this.handleRowClick((_a = _this.state.product) === null || _a === void 0 ? void 0 : _a.productId); } },
                        React.createElement("div", { className: 'chevron-container' },
                            React.createElement("i", { className: "fal ".concat(((_2 = this.state.openedRows) === null || _2 === void 0 ? void 0 : _2.includes((_3 = this.state.product) === null || _3 === void 0 ? void 0 : _3.productId)) ? 'fa-chevron-up' : 'fa-chevron-down') })),
                        React.createElement(table_body_cell_1.TableBodyCell, { width: 27 },
                            React.createElement("div", null,
                                React.createElement("p", null,
                                    React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "EAN"),
                                    React.createElement("span", null, ean)),
                                React.createElement("p", null,
                                    React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "SKU"),
                                    React.createElement("span", null, sku)),
                                React.createElement("p", null,
                                    manufacturer,
                                    " ",
                                    title,
                                    " ",
                                    vintage,
                                    " (",
                                    capacity,
                                    ") ",
                                    drsText))),
                        React.createElement(table_body_cell_1.TableBodyCell, { width: 35 },
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.fromStorage, type: storage_by_product_tree_select_form_control_type_1.StorageByProductTreeSelectFormControlType },
                                React.createElement(tree_select_1.TreeSelect, { onlyAllowedStorages: true, warehouseToFilterBy: this.props.sourceWarehouse, multi: false, enabledTypes: ['storage'], modalTitle: 'Forrás tárhely választása', searchable: true, className: 'select-input-basic', products: [(_4 = this.state.product.product) === null || _4 === void 0 ? void 0 : _4._id] }))),
                        React.createElement(table_body_cell_1.TableBodyCell, { width: 35 }, this.state.isFromStorageSelected && this.props.targetWarehouse
                            && React.createElement(form_control_1.FormControl, { field: this.form.fields.toStorage, type: single_storage_tree_select_form_control_type_1.SingleStorageTreeSelectFormControlType },
                                React.createElement(tree_select_1.TreeSelect, { warehouseToFilterBy: this.props.targetWarehouse, multi: false, enabledTypes: ['storage'], modalTitle: 'Cél tárhely választása', searchable: false, className: 'select-input-basic' }))),
                        React.createElement(table_body_cell_1.TableBodyCell, { width: 3, className: 'justify-content-center' }, this.deleteButton(this.state.product.productId)))),
                !!((_6 = (_5 = this.state.stockProducts) === null || _5 === void 0 ? void 0 : _5.data) === null || _6 === void 0 ? void 0 : _6.length)
                    && React.createElement(table_body_cell_1.TableBodyCell, { width: 100, className: "".concat(((_7 = this.state.openedRows) === null || _7 === void 0 ? void 0 : _7.includes((_8 = this.state.product) === null || _8 === void 0 ? void 0 : _8.productId)) ? 'opened' : 'closed') },
                        React.createElement(table_body_row_1.TableBodyRow, { width: 100, className: 'flex-column wrapper' },
                            React.createElement(table_body_cell_1.TableBodyCell, { width: 100 },
                                React.createElement("div", { style: { width: 32 } }),
                                React.createElement(table_header_row_1.TableHeaderRow, { className: 'inner-table-header' },
                                    React.createElement("div", { style: { width: 32 } }),
                                    React.createElement(table_header_cell_1.TableHeaderCell, { width: 66, title: 'LOT/NÉBIH', property: 'lot', className: 'pr-3' }),
                                    React.createElement(table_header_cell_1.TableHeaderCell, { width: 17, title: 'ÖSSZMENNYISÉG', property: 'totalAmount', className: 'pr-3' }),
                                    React.createElement(table_header_cell_1.TableHeaderCell, { width: 17, title: 'VÁLASZTOTT MENNY.', property: 'amount' }))), (_10 = (_9 = this.state.stockProducts) === null || _9 === void 0 ? void 0 : _9.data) === null || _10 === void 0 ? void 0 :
                            _10.map(function (item) {
                                var _a, _b, _c;
                                return React.createElement(table_body_cell_1.TableBodyCell, { width: 100, key: item.lot },
                                    React.createElement(table_body_row_1.TableBodyRow, { className: 'flex-column wrapper' },
                                        React.createElement(table_body_cell_1.TableBodyCell, { width: 100 },
                                            React.createElement("div", { style: { width: 32 } }),
                                            React.createElement(table_body_row_1.TableBodyRow, { className: 'align-items-center content', onClick: function () { var _a; return _this.handleRowClick("".concat((_a = _this.state.product) === null || _a === void 0 ? void 0 : _a.productId, " - ").concat(item.lot)); } },
                                                React.createElement("div", { className: 'chevron-container' },
                                                    React.createElement("i", { className: "fal ".concat(((_a = _this.state.openedRows) === null || _a === void 0 ? void 0 : _a.includes("".concat((_b = _this.state.product) === null || _b === void 0 ? void 0 : _b.productId, " - ").concat(item.lot))) ? 'fa-chevron-up' : 'fa-chevron-down') })),
                                                React.createElement(table_body_cell_1.TableBodyCell, { width: 66, className: 'pr-3' },
                                                    React.createElement("p", null, item.lot)),
                                                React.createElement(table_body_cell_1.TableBodyCell, { width: 17, className: 'justify-content-end pr-3' },
                                                    React.createElement("p", null,
                                                        "\u2211 ",
                                                        item.sum,
                                                        " db")),
                                                React.createElement(table_body_cell_1.TableBodyCell, { width: 17, className: 'justify-content-end' },
                                                    React.createElement("p", null,
                                                        "\u2211 ",
                                                        item.sum,
                                                        " db")))), (_c = item.lotItems) === null || _c === void 0 ? void 0 :
                                        _c.map(function (l, index) {
                                            var _a, _b, _c, _d, _e;
                                            return (React.createElement(table_body_cell_1.TableBodyCell, { width: 100, key: l.type + index, className: "".concat(((_a = _this.state.openedRows) === null || _a === void 0 ? void 0 : _a.includes("".concat((_b = _this.state.product) === null || _b === void 0 ? void 0 : _b.productId, " - ").concat(item.lot))) ? 'opened' : 'closed') },
                                                React.createElement(table_body_row_1.TableBodyRow, { width: 100, className: 'flex-column wrapper' },
                                                    React.createElement(table_body_cell_1.TableBodyCell, { width: 100 },
                                                        React.createElement("div", { style: { width: 64 } }),
                                                        l.type !== 'available' && React.createElement(table_body_row_1.TableBodyRow, { className: "".concat(l.type, " align-items-center content"), onClick: function () { var _a; return _this.handleRowClick("".concat((_a = _this.state.product) === null || _a === void 0 ? void 0 : _a.productId, " - ").concat(item.lot, " - ").concat(l.type)); } },
                                                            React.createElement("div", { className: 'chevron-container' },
                                                                React.createElement("i", { className: "fal ".concat(((_c = _this.state.openedRows) === null || _c === void 0 ? void 0 : _c.includes("".concat((_d = _this.state.product) === null || _d === void 0 ? void 0 : _d.productId, " - ").concat(item.lot, " - ").concat(l.type))) ? 'fa-chevron-up' : 'fa-chevron-down') })),
                                                            React.createElement(table_body_cell_1.TableBodyCell, { width: 66, className: 'pr-3' },
                                                                React.createElement("p", null, _this.getTypeTitle(l.type))),
                                                            React.createElement(table_body_cell_1.TableBodyCell, { width: 17, className: 'justify-content-end pr-3' },
                                                                React.createElement("p", null,
                                                                    "\u2211 ",
                                                                    l.sum,
                                                                    " db")),
                                                            React.createElement(table_body_cell_1.TableBodyCell, { width: 17, className: 'justify-content-end' },
                                                                React.createElement("p", null,
                                                                    "\u2211 ",
                                                                    l.sum,
                                                                    " db")))), (_e = l.typeItems) === null || _e === void 0 ? void 0 :
                                                    _e.map(function (t, i) {
                                                        var _a, _b;
                                                        return React.createElement(stock_transaction_create_table_stock_product_row_1.StockTransactionCreateTableStockProductRow, { key: t.type + i, item: t, ref: function (ref) { return _this.stockProductsRefs[t._id || t.productLot] = ref; }, opened: (_a = _this.state.openedRows) === null || _a === void 0 ? void 0 : _a.includes("".concat((_b = _this.state.product) === null || _b === void 0 ? void 0 : _b.productId, " - ").concat(item.lot, " - ").concat(l.type)) });
                                                    }))));
                                        })));
                            })))));
    };
    StockTransactionCreateTableRow.prototype.getData = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        return __awaiter(this, void 0, void 0, function () {
            var errors, hasError, data, result, _s, _t, stock, stockItem, type, amount, ref, productLot, e_2_1;
            var e_2, _u;
            return __generator(this, function (_v) {
                switch (_v.label) {
                    case 0: return [4, this.form.validate()];
                    case 1:
                        _v.sent();
                        return [4, ((_a = this.form) === null || _a === void 0 ? void 0 : _a.getErrors())];
                    case 2:
                        errors = _v.sent();
                        hasError = false;
                        (0, lodash_1.map)(errors, function (e) {
                            hasError = hasError || !!e.length;
                        });
                        if (hasError) {
                            if ((_b = errors === null || errors === void 0 ? void 0 : errors.storage) === null || _b === void 0 ? void 0 : _b.length) {
                                this.setState({ storageError: true });
                            }
                            return [2, { error: true }];
                        }
                        data = this.form.toJSON();
                        result = [];
                        _v.label = 3;
                    case 3:
                        _v.trys.push([3, 11, 12, 13]);
                        _s = __values(this.state.stockProducts.refs), _t = _s.next();
                        _v.label = 4;
                    case 4:
                        if (!!_t.done) return [3, 10];
                        stock = _t.value;
                        stockItem = this.stockProductsRefs[stock];
                        if (!((_d = (_c = stockItem.form) === null || _c === void 0 ? void 0 : _c.fields) === null || _d === void 0 ? void 0 : _d.amount.getValue())) {
                            return [3, 9];
                        }
                        return [4, ((_g = (_f = (_e = stockItem.form) === null || _e === void 0 ? void 0 : _e.fields) === null || _f === void 0 ? void 0 : _f.type) === null || _g === void 0 ? void 0 : _g.getValue())];
                    case 5:
                        type = _v.sent();
                        return [4, ((_k = (_j = (_h = stockItem.form) === null || _h === void 0 ? void 0 : _h.fields) === null || _j === void 0 ? void 0 : _j.amount) === null || _k === void 0 ? void 0 : _k.getValue())];
                    case 6:
                        amount = _v.sent();
                        return [4, ((_o = (_m = (_l = stockItem.form) === null || _l === void 0 ? void 0 : _l.fields) === null || _m === void 0 ? void 0 : _m.ref) === null || _o === void 0 ? void 0 : _o.getValue())];
                    case 7:
                        ref = _v.sent();
                        return [4, ((_r = (_q = (_p = stockItem.form) === null || _p === void 0 ? void 0 : _p.fields) === null || _q === void 0 ? void 0 : _q.productLot) === null || _r === void 0 ? void 0 : _r.getValue())];
                    case 8:
                        productLot = _v.sent();
                        result.push(__assign({ productId: data.product, fromStorage: data.fromStorage, toStorage: data.toStorage, type: type, amount: parseInt(amount, 10), productLot: productLot }, (type === 'available' ? {} : { refId: ref })));
                        _v.label = 9;
                    case 9:
                        _t = _s.next();
                        return [3, 4];
                    case 10: return [3, 13];
                    case 11:
                        e_2_1 = _v.sent();
                        e_2 = { error: e_2_1 };
                        return [3, 13];
                    case 12:
                        try {
                            if (_t && !_t.done && (_u = _s.return)) _u.call(_s);
                        }
                        finally { if (e_2) throw e_2.error; }
                        return [7];
                    case 13: return [2, result];
                }
            });
        });
    };
    StockTransactionCreateTableRow.prototype.getLotData = function (raw) {
        var _a, _b, _c;
        var data = (0, lodash_1.cloneDeep)(raw);
        var sum = 0;
        var refs = [];
        data = (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.map(function (item) {
            if ((item.type === 'order-lock' || item.type === 'issue-lock') && (item.lockType !== 'available')) {
                var ref = data.find(function (d) { return d._id === item.lockTypePayload; });
                ref.locks = !!ref.lock ? __spreadArray([], __read(ref.locks), false) : [];
                ref.locks.push(item);
                ref.amount += item.amount;
                return;
            }
            return item;
        })) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return !!item; })) === null || _b === void 0 ? void 0 : _b.sort(function (a, b) { return a.type - b.type; })) === null || _c === void 0 ? void 0 : _c.reduce(function (result, i) {
            var _a, _b, _c, _d;
            var lotIndex = result.indexOf(result.find(function (l) { return l.lot === i.productLot; }));
            var lotPosition = lotIndex < 0 ? result.length : lotIndex;
            var lot = result[lotPosition] = result[lotPosition] || { lot: i.productLot, lotItems: [], sum: 0 };
            var typeIndex = (_a = lot.lotItems) === null || _a === void 0 ? void 0 : _a.indexOf((_b = lot.lotItems) === null || _b === void 0 ? void 0 : _b.find(function (t) { return t.type === i.type; }));
            var typePosition = typeIndex < 0 ? (_c = lot.lotItems) === null || _c === void 0 ? void 0 : _c.length : typeIndex;
            var type = lot.lotItems[typePosition] = lot.lotItems[typePosition] || { type: i.type, typeItems: [], sum: 0 };
            (_d = type.typeItems) === null || _d === void 0 ? void 0 : _d.push(i);
            refs.push(i._id || i.productLot);
            type.sum += i.amount;
            lot.sum += i.amount;
            sum += i.amount;
            return result;
        }, []);
        return { data: data, sum: sum, refs: refs };
    };
    StockTransactionCreateTableRow.prototype.deleteButton = function (productId) {
        var _this = this;
        return React.createElement(button_1.Button, { className: 'delete-button', onClick: function () { return _this.props.showDeleteProductModal(productId); }, iconLeft: 'fas fa-trash' });
    };
    StockTransactionCreateTableRow.prototype.handleRowClick = function (rowId) {
        if (this.state.openedRows.includes(rowId)) {
            var newOpened = (0, lodash_1.cloneDeep)(this.state.openedRows).filter(function (row) { return row !== rowId; });
            return this.setState({ openedRows: newOpened });
        }
        this.setState({ openedRows: __spreadArray(__spreadArray([], __read(this.state.openedRows), false), [rowId], false) });
    };
    StockTransactionCreateTableRow.prototype.getTypeTitle = function (type) {
        switch (type) {
            case 'cp-reservation':
                return 'Vevő cég foglalás';
            case 'ch-reservation':
                return 'Csatorna foglalás';
            case 'order-lock':
                return 'Megrendelés zárolás';
            case 'issue-lock':
                return 'Kitárolás zárolás';
            default:
                return 'Szabad készlet';
        }
    };
    return StockTransactionCreateTableRow;
}(abstract_component_1.AbstractComponent));
exports.StockTransactionCreateTableRow = StockTransactionCreateTableRow;
